import './DataComponent.css';

function DataComponent(props) {
    return(
        <div className="dataComponent">
            <div className="dataComponentTitle">
                {props.title}
            </div>
            <div className="dataComponentValue">
                {props.value}
            </div>
            <div className="dataComponentValue">
                {props.value2}
            </div>
        </div>
    );
}

export default DataComponent;