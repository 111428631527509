import React, { useState } from 'react';
import DataComponent from './DataComponent.js';

const poczatekZacmienia = 1623318450000;
const maksimumZacmienia = 1623322134000;
const koniecZacmienia = 1623325869000;

function TimerControls(props) {
    const [state, setState] = useState("...");

    fetch("http://localhost:8090/api/main/status")
    .then(response => response.json()).then(json => json.selectioninfo)
    .then(selInfo => selInfo.indexOf("Stopień zaćmienia:") === -1 ? "0%" : selInfo.slice(selInfo.indexOf("Stopień zaćmienia:")+19,selInfo.indexOf("Stopień zaćmienia:")+24))
    .then(percentage => percentage.slice(percentage.length-1) === "%" ? percentage : percentage+"%")
    .then(result => setState(result))
    .catch(error => setState("..."))


    /* Order of rendering states:
        1. before the eclipse
        2. after the start, before maximum
        3. after the start, after maximum
        4. after the eclipse
    */

    return(
        <div>
            <div>
                {props.time < poczatekZacmienia ? <DataComponent title="Czas do początku zjawiska" value={secondsToString(poczatekZacmienia-props.time)} /> : <DataComponent title="Czas od początku zjawiska" value={secondsToString(props.time-poczatekZacmienia)} />}
                {props.time > poczatekZacmienia && props.time < maksimumZacmienia ? <DataComponent title="Czas do maksimum zjawiska" value={secondsToString(maksimumZacmienia-props.time)} /> : <div></div>}
                {props.time > poczatekZacmienia && props.time < koniecZacmienia ? <DataComponent title="Czas do końca zjawiska" value={secondsToString(koniecZacmienia-props.time)} /> : <div></div>}
                



                {props.time > koniecZacmienia ? <DataComponent title="Czas od końca zjawiska" value={secondsToString(props.time-koniecZacmienia)} /> : <div></div>}
                
            </div>
            
            <div style={{display: "flex"}}>
                <DataComponent title="Aktualny stopień zaćmienia" value={state} />
                <div style={{width: "70px"}}></div>
                <DataComponent title="Maksymalna wartość" value="11.98%" />
            </div>

        </div>
    );
}

function secondsToString(difference) {

    let seconds = Number(Math.floor(difference/1000))

    let hour = Math.floor(seconds/3600);
    let minute = Math.floor((seconds-hour*3600)/60);
    let rest = Math.floor((seconds-hour*3600)-minute*60);

    //return(seconds);
    //return(addLeadingZeros(hour)+" godzin, "+addLeadingZeros(minute)+" minut, "+addLeadingZeros(rest)+" sekund");
    
    return(addLeadingZeros(hour)+" godzin, "+addLeadingZeros(minute)+" minut");
}

function addLeadingZeros(number) {
    if (number < 10) {
      return("0" + number);
    } else {
      return(number);
    }
}

export default TimerControls;