import React from 'react';
import './App.css';

import DataComponent from './DataComponent.js';
import TimerControls from './TimerControls.js';


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { currentTime: new Date() };
  }

  tick() {
    this.setState(state => ({
      currentTime: new Date()
    }));
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }


  render() {
    return (
      <div className="mainContainer">
        <div className="headerContainer">
          <img src="/header.png" alt=""></img>
        </div>
        <div className="backgroundLayerContainer">
          <img src="/back1.png" alt=""></img>
        </div>
        <div className="set1Controls">
          <div className="datacomponentContainer">
            <DataComponent title="Data" value="10 czerwca 2021" />
          </div>
          
          <div className="datacomponentContainer" style={{"display": "flex"}}>
            <DataComponent title="Czas uniwersalny" value={addLeadingZeros(this.state.currentTime.getUTCHours()) + ":" + addLeadingZeros(this.state.currentTime.getUTCMinutes()) + ":" + addLeadingZeros(this.state.currentTime.getUTCSeconds())} />
            <div style={{"width": "70px"}}></div>
            <DataComponent title="Czas lokalny" value={addLeadingZeros(this.state.currentTime.getHours()) + ":" + addLeadingZeros(this.state.currentTime.getMinutes()) + ":" + addLeadingZeros(this.state.currentTime.getSeconds())} />
          </div>
          
          <div className="datacomponentContainer">
            <DataComponent title="Miejsce obserwacji" value="Inowrocław, Polska" value2="52.79°N, 18.26°S" />
          </div>
        </div>
        <div className="set2Controls">
          <TimerControls time={this.state.currentTime} />
        </div>
      </div>
    );
  }
}

export default App;


function addLeadingZeros(number) {
  if (number < 10) {
    return("0" + number);
  } else {
    return(number);
  }
}